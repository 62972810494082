<template>
  <div>
    <SubHeaderBlock />
    <div class="container">
      <component :is="block" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    SubHeaderBlock: () => ({
      component: import("./components/subHeader"),
    }),
    OrdersBlock: () => ({
      component: import("./components/report"),
    }),
    FuelBlock: () => ({
      component: import("./components/fuel"),
    }),
    transportOilBlock: () => ({
      component: import("./components/transportOil"),
    }),
    oneCBlock: () => ({
      component: import("./components/oneC"),
    }),
    unsettingPointsBlock: () => ({
      component: import("./components/unsettingPoints"),
    }),
  },
  computed: {
    block() {
      return this.$route.params.type + "-block";
    },
  },
};
</script>

<style scoped></style>
